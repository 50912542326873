import { apiCaller } from "./../_helpers";
import axios from "axios";
import { alertActions } from "./";
import {
  configConstants,
  studentConstants,
  teacherConstants,
  userConstants,
} from "./../_constants";
import { history } from "../_helpers";
import { isNull } from "lodash";
import { teacherService } from "../_services";
import { teacherActions } from "./";
import APIBase from "../_base/APIBase";

export const userActions = {
  login,
  udpateInformation,
  forgotPassword,
  resetPassword,
  register,
  uploadAvatar,
  getRoomChat,
  getPrivateMessages,
  getSystemNotification,
  getDetailMessage,
  sendMessage,
  getInfoContact,
  getDeviceDetail,
  saveUserSetting,
  updateStatusInbox,
};

function login(data, type = "", typeWeb) {
  const device_id = data["device_id"];
  return (dispatch) => {
    let url_call_api = "/api_login/check";
    let method = "PUT";
    if (type === configConstants.LOGIN_VIA_FACEBOOK) {
      url_call_api = "/api_login/facebook_auth";
      method = "POST";
      return callApiLoginSocialMedia(
        url_call_api,
        method,
        data,
        type,
        device_id,
        dispatch,
        typeWeb
      );
    } else if (type === configConstants.LOGIN_VIA_GOOLE) {
      url_call_api = "/api_login/google_auth";
      method = "POST";
      return callApiLoginSocialMedia(
        url_call_api,
        method,
        data,
        type,
        device_id,
        dispatch,
        typeWeb
      );
    } else if (type === configConstants.LOGIN_VIA_APPLE) {
      url_call_api = "/api_login/apple_auth";
      method = "POST";
      return callApiLoginSocialMedia(
        url_call_api,
        method,
        data,
        type,
        device_id,
        dispatch,
        typeWeb
      );
    }
    return apiCaller(url_call_api, method, data, null, false).then(
      async (data) => {
        if (data?.data_user?.role == "parent") {
          const warningText = 'Hiện tại chúng tôi chưa hỗ trợ tài khoản phụ huynh trên website. Vui lòng tải app Sunday Parent và đăng nhập để sử dụng.'
          dispatch(
            alertActions.error({
              message: warningText,
              screen: userConstants.SCREEN_LOGIN,
            })
          ); 
          return
        }
        let url_auto_login_for_teacher = `${configConstants.BASE_URL}login/api_teacher_auto_login`;
        let url_auto_login = `${configConstants.EXCERCISE_URL}login/api_auto_login`;
        let user = data?.data_user;
        user.isLogin = true;
        user.jwt_token = data.jwt_token;
        user.is_new_acc = data.is_new_acc;
        user.loginType = type;
        const dataUser = new FormData();
        dataUser.append("user_id", data?.data_user?.id);
        dataUser.append("jwt_token", data?.jwt_token);
        dataUser.append("signature", "1");
        if (data?.data_user?.role == "teacher") {
          await APIBase.apiBaseCaller(
            "POST",
            url_auto_login_for_teacher,
            dataUser
          )
            .then((apiExerciseToWeb) => {
              if (
                apiExerciseToWeb.code == 0 ||
                apiExerciseToWeb.message == "Login_Is_Success"
              ) {
                dispatch({
                  type: teacherConstants.GET_SERVICE_TEACHER,
                  data: apiExerciseToWeb,
                });
              } else {
                dispatch({
                  type: teacherConstants.GET_SERVICE_TEACHER,
                  data: {
                    redirect_url: "",
                    message: apiExerciseToWeb.message,
                    status: false,
                  },
                });
              }
            })
            .catch((error) => {
              return dispatch(
                alertActions.error({
                  message: error?.message?.toString(),
                  screen: userConstants.SCREEN_LOGIN,
                  data: error?.message,
                })
              );
            });
        }
        // else if (data?.data_user?.role == "student") {
        //   await APIBase.apiBaseCaller("POST", url_auto_login, dataUser)
        //     .then((apiExerciseToWeb) => {
        //       if (apiExerciseToWeb.code == 0) {
        //         dispatch({
        //           type: studentConstants.GET_REDIRECT_URL,
        //           data: {
        //             redirect_url: apiExerciseToWeb?.url_data?.redirect_url,
        //             message: apiExerciseToWeb.message,
        //             status: true,
        //           },
        //         });
        //       } else {
        //         dispatch({
        //           type: studentConstants.GET_REDIRECT_URL,
        //           data: {
        //             redirect_url: "",
        //             message: apiExerciseToWeb.message,
        //             status: false,
        //           },
        //         });
        //       }
        //     })
        //     .catch((error) => {});
        // }

        dispatch({
          type: userConstants.LOGIN,
          user,
        });
        localStorage.setItem("authentication", JSON.stringify(user));
        localStorage.setItem("access_token", JSON.stringify(data.access_token));
        if (
          data?.data_user?.last_login == null &&
          data?.data_user?.role == userConstants.ROLE_STUDENT
        ) {
          dispatch({ type: userConstants.ON_ROOTLESSNESS });
          dispatch({ type: userConstants.SHOW_SIDEBAR_ROOTLESSNESS });
        }
        dispatch(teacherActions.getProfileV2(user.id));

        let keyId = "device_id_" + data.id;
        const cachedDeviceId = localStorage.getItem(keyId);
        if (isNull(cachedDeviceId)) {
          localStorage.setItem(keyId, device_id);
          localStorage.setItem("device_id_commond", device_id);
        } else {
          localStorage.setItem("device_id_commond", cachedDeviceId);
        }

        const purposeLogin = JSON.parse(localStorage.getItem("purposeLogin"));

        let authentication = JSON.parse(localStorage.getItem("authentication"));
        // typeWeb == "news" ? history.push("/") : purposeLogin.purpose history.push("/" + user.role);
        if (typeWeb == "news") {
          history.push("/");
        } else {
          if (purposeLogin) {
            switch (purposeLogin?.purpose) {
              case "mock_test":
                if (authentication.role == userConstants.ROLE_STUDENT) {
                  history.push(
                    `/${authentication?.role}/exam-test/${purposeLogin.exam_id}/experience`
                  );
                } else {
                  history.push(`/exam-test/${purposeLogin.exam_id}/experience`);
                }
                return;
              case "curriculum":
                history.push(`/${authentication.role}/curriculum`);
                return;
              default:
                history.push("/" + user.role);
            }
            localStorage.removeItem("purposeLogin");
          } else {
            history.push("/" + user.role);
          }
        }
      },
      (error) => {
        if (error.toString() === "Vui lòng nhập mật khẩu.") {
          error = "Mật khẩu cần tối thiểu 6 kí tự và không gồm toàn dấu cách";
        }
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: userConstants.SCREEN_LOGIN,
            data: error,
          })
        );
        if (error.toString()?.includes("Tài khoản chưa được xác thực")) {
          dispatch({ type: userConstants.REGISTER_STATUS });
        }
        // dispatch({
        //     type: userConstants.CHANGE_LOGIN_TYPE,
        //     data
        // });
        // history.push('/register');
      }
    );
  };
}

function callApiLoginSocialMedia(
  url_call_api,
  method,
  data,
  type,
  device_id,
  dispatch,
  typeWeb
) {
  return apiCallerSocialMedia(url_call_api, method, data, null, false).then(
    (data) => {
      if (data.data_user) {
        // console.log(data.data_user)
        let user = data?.data_user;
        user.isLogin = true;
        user.jwt_token = data.jwt_token;
        user.is_new_acc = data.is_new_acc;
        user.loginType = type;
        localStorage.setItem("authentication", JSON.stringify(user));
        // console.log("disconnect: ", type);
        localStorage.setItem("access_token", JSON.stringify(data.access_token));
        if (type === configConstants.LOGIN_VIA_GOOLE) {
          window.gapi.auth2.getAuthInstance().disconnect();
          window.gapi.auth2?.getAuthInstance()?.signOut &&
            window.gapi.auth2.getAuthInstance().signOut();
        }
        if (type === configConstants.LOGIN_VIA_FACEBOOK) {
          if (window.FB) {
            window.FB.api("/me/permissions", "delete", null, () =>
              window.FB.logout()
            );
          }
        }
        dispatch({
          type: userConstants.LOGIN,
          user,
        });
        dispatch(teacherActions.getProfileV2(user.id));
        let keyId = "device_id_" + data.id;
        const cachedDeviceId = localStorage.getItem(keyId);
        if (isNull(cachedDeviceId)) {
          localStorage.setItem(keyId, device_id);
          localStorage.setItem("device_id_commond", device_id);
        } else {
          localStorage.setItem("device_id_commond", cachedDeviceId);
        }
        const purposeLogin = JSON.parse(localStorage.getItem("purposeLogin"));

        let authentication = JSON.parse(localStorage.getItem("authentication"));
        // typeWeb == "news" ? history.push("/") : purposeLogin.purpose history.push("/" + user.role);
        if (typeWeb == "news") {
          history.push("/");
        } else {
          if (purposeLogin) {
            switch (purposeLogin?.purpose) {
              case "mock_test":
                if (authentication.role == userConstants.ROLE_STUDENT) {
                  history.push(
                    `/${authentication?.role}/exam-test/${purposeLogin.exam_id}/experience`
                  );
                } else {
                  history.push(`/exam-test/${purposeLogin.exam_id}/experience`);
                }
                return;
              case "curriculum":
                history.push(`/${authentication.role}/curriculum`);
                return;
              default:
                history.push("/" + user.role);
            }
            localStorage.removeItem("purposeLogin");
          } else {
            history.push("/" + user.role);
          }
        }
      } else if (!data.data_user && data.is_new_acc === "1") {
        dispatch({
          type: userConstants.LOGIN,
          user: {
            loginType: type,
            is_new_acc: true,
          },
        });
        typeWeb == "news" && history.push("/register_news/google");
        // dispatch(teacherActions.getProfile(user.id));
      } else {
        dispatch(
          alertActions.error({
            message: data?.msg?.toString(),
            screen: userConstants.SCREEN_LOGIN,
            data: data.msg,
          })
        );
      }
    },
    (error) => {
      dispatch(
        alertActions.error({
          message: error.toString(),
          screen: userConstants.SCREEN_LOGIN,
          data: error,
        })
      );
      // dispatch({
      //     type: userConstants.CHANGE_LOGIN_TYPE,
      //     data
      // });
      // history.push('/register');
    }
  );
}

function register(data) {
  return (dispatch) => {
    return apiCaller("/api_register/register", "POST", data, null, false).then(
      () => {
        dispatch(
          alertActions.success({
            message: userConstants.MESSAGE_REGISTER_COMPLETE,
            screen: userConstants.SCREEN_COMPLETE_DATA_REGISTER,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: userConstants.SCREEN_COMPLETE_DATA_REGISTER,
          })
        );
      }
    );
  };
}

function udpateInformation(data) {
  return (dispatch) => {
    dispatch({
      type: userConstants.ADD_DATA_REGISTER,
      data,
    });
    history.push("/add-infomation");
  };
}

function forgotPassword(email) {
  return (dispatch) => {
    return apiCaller(
      "/api_login/forget_password",
      "PUT",
      { email, forget_password_web: "1" },
      null,
      false
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: userConstants.MESSAGE_REQUEST_CHANGE_PASSWORD_COMPLETE,
            screen: userConstants.SCREEN_FORGOT_PASSWORD,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: userConstants.SCREEN_FORGOT_PASSWORD,
          })
        );
      }
    );
  };
}

function resetPassword(data) {
  return (dispatch) => {
    return apiCaller(
      "/api_login/reset_password",
      "PUT",
      data,
      null,
      false
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: userConstants.MESSAGE_CHANGE_PASSWORD_COMPLETE,
            screen: userConstants.SCREEN_RESET_PASSWORD,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: userConstants.SCREEN_RESET_PASSWORD,
          })
        );
      }
    );
  };
}

function uploadAvatar(data) {
  return (dispatch) => {
    return apiCaller("/api_user/update_avatar", "POST", data, null, false).then(
      () => {
        // dispatch(alertActions.success({ 'message': userConstants.MESSAGE_UPDATE_AVATAR_COMPLETE, 'screen': userConstants.SCREEN_UPDATE_PROFILE }));
        console.log("upload avatar success");
      },
      (error) => {
        // dispatch(alertActions.error({ 'message': error.toString(), 'screen': userConstants.SCREEN_UPDATE_PROFILE }));
        console.log(error);
      }
    );
  };
}

function getRoomChat(id) {
  return (dispatch) => {
    return apiCaller(
      "/api_inbox/check_room?to_user_id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      dispatch({
        type: userConstants.GET_ROOM_CHAT,
        data: data.room_id,
      });
      dispatch(userActions.getDetailMessage(data.room_id));
    });
  };
}

function getPrivateMessages() {
  return (dispatch) => {
    return apiCaller("/api_inbox/inbox", "GET", {}, null, true).then(
      (data_message) => {
        dispatch({
          type: userConstants.GET_CLASS_MESSAGE,
          data_message,
        });
      }
    );
  };
}

function getInfoContact(class_id, role) {
  const url = class_id ? "&class_id=" + class_id : "";
  return (dispatch) => {
    return apiCaller(
      "/api_inbox/list_contact?user_role=" + role + url,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      dispatch({
        type: userConstants.GET_CONTACT_INFO,
        data: data.data_contact,
        base_url: data.base_url,
      });
      dispatch({
        type: userConstants.GET_DETAIL_MESSAGE,
        data: [],
      });
    });
  };
}

function getDetailMessage(id, latest_msg_id, latest_msg_status) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("inbox_id", latest_msg_id);
  urlencoded.append("seen_status", "1");
  urlencoded.append("subject", "");
  urlencoded.append("content", "");
  return (dispatch) => {
    return apiCaller(
      "/api_inbox/inbox_detail?id=" + id,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      dispatch({
        type: userConstants.GET_DETAIL_MESSAGE,
        data: data.data,
      });
      if (urlencoded && latest_msg_id && latest_msg_status === 0) {
        // console.log(latest_msg_status);
        dispatch(updateStatusInbox(urlencoded));
      }
    });
  };
}

function sendMessage(data, roomId) {
  return (dispatch) => {
    return apiCaller("/api_inbox/send", "POST", data, null, false).then(
      () => {
        dispatch(userActions.getDetailMessage(roomId));
        dispatch(userActions.getPrivateMessages());
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

function getSystemNotification(class_id) {
  const url = class_id ? "?class_id=" + class_id : "";
  return (dispatch) => {
    return apiCaller(
      "/api_inbox/inbox_system" + url,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      dispatch({
        type: userConstants.GET_CLASS_NOTIFICATION,
        data: data.data,
      });
    });
  };
}

function getDeviceDetail(device_id) {
  return (dispatch) => {
    return apiCaller(
      "/api_user/load_setting_app?device_id=" + device_id,
      "GET",
      {},
      null,
      true
    ).then((data) => {
      dispatch({
        type: userConstants.GET_SETTING_DETAIL,
        data: data.data_setting,
      });
    });
  };
}

function saveUserSetting(data, user_device_id) {
  var urlencoded = new URLSearchParams();
  urlencoded.append("user_device_id", user_device_id);
  urlencoded.append("json_setting", JSON.stringify(data));
  return () => {
    return apiCaller(
      "/api_user/save_setting",
      "PUT",
      urlencoded,
      null,
      false
    ).then(
      () => {
        console.log("save success");
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

function updateStatusInbox(data) {
  return (dispatch) => {
    return apiCaller("/api_inbox/update_inbox", "PUT", data, null, false).then(
      () => {
        teacherService.getInboxInfo().then((data) => {
          dispatch({
            type: userConstants.GET_TOTAL_MESSAGE,
            number_msg_new: data.number_msg_new,
            number_system_new: data.number_system_new,
          });
        });
      },
      (error) => {
        console.log(error);
      }
    );
  };
}

function apiCallerSocialMedia(
  endpoint,
  method = "GET",
  body = {},
  headers = null,
  clearToken = true,
  basUrl = configConstants.API_URL_SETEST
) {
  if (headers === undefined || headers === null) {
    headers = {
      "X-API-KEY": configConstants.API_KEY,
    };
    if (localStorage.getItem("authentication")) {
      let authentication = JSON.parse(localStorage.getItem("authentication"));
      headers["jwt_token"] = authentication.jwt_token;
    }
  }
  $(".loading").removeClass("hide");
  return axios({
    method,
    timeout: configConstants.API_TIMEOUT,
    url: `${basUrl}${endpoint}`,
    auth: {
      username: configConstants.AUTH_BASIC_USER_NAME,
      password: configConstants.AUTH_BASIC_PASSWORD,
    },
    data: body,
    headers: headers,
  })
    .then(handleResponse)
    .catch((error) => {
      $(".loading").addClass("hide");
      if (error && error.response) {
        if (clearToken && error.response.status === 401) {
          clearLocalStorage();
        } else {
          let msg = "Server Error";
          if (error.response.data.msg || error.response.data.error) {
            msg = error.response.data.msg
              ? error.response.data.msg
              : error.response.data.error;
          }
          return Promise.reject(msg);
        }
      } else {
        if (typeof error === "string" || error instanceof String) {
          return Promise.reject(error);
        } else {
          return Promise.reject("Server Error");
        }
      }
    });
}

function clearLocalStorage() {
  // localStorage.clear();
  localStorage.removeItem("authentication");
  localStorage.removeItem("access_token");
  history.push("/login");
}

function handleResponse(response) {
  $(".loading").addClass("hide");
  return response.data;
}
