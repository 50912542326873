import { useEffect, useState } from 'react';
import './index.scss'
import { apiCaller, history } from '../../../../_helpers';
import InputSelectSearch from '../../../../_components/Auth/InputSelectSearch';
import { renderLocationIcon, renderSchoolIcon } from './renderIcon';
import { stepAuthConstants } from '../../../../_constants/auth';
import { useParams } from 'react-router-dom';
import { registerValue } from '../../../../_constants/register';

export default function AddressStudying(props) {
  const params = useParams()
  const orgLevel = params?.orgLevel
  const [school, setSchool] = useState({
    title: '',
    value: '',
    id: '',
  });
  // address
  const [provinceList, setProvinceList] = useState([]);
  const [province, setProvince] = useState({
    title: "",
    value: props?.registerInfo?.province_alias || "",
  });
  const [districtList, setDistrictList] = useState([]);
  const [district, setDistrict] = useState({
    title: "",
    value: props?.registerInfo?.district_alias || "",
  });
  const [schoolList, setSchoolList] = useState([]);
  const [errProvince, setErrProvince] = useState("");
  const [errDistrict, setErrDistrict] = useState("");
  const [organizationList, setOrganizationList] = useState([])
  const [organization, setOrganization] = useState({
    title: '',
    value: '',
    id: ''
  });

  const getProvinceList = () => {
    apiCaller("/api_category/provinces?country_code=vn").then((res) => {
      if (res.status) {
        setProvinceList(res.data);
      }
    });
  };

  const getDistrictList = (provinceAlias) => {
    apiCaller(`/api_category/districts?province_alias=${provinceAlias}`).then(
      (res) => {
        if (res.status) {
          setDistrictList(res.data);
        }
      }
    );
  };

  const getSchoolList = (districtAlias) => {
      apiCaller(`/api_category/schools?district_alias=${districtAlias}`).then(
        (res) => {
          if (res.status) {
            setSchoolList(res.data);
          }
        }
      );
  };

  const getOrganizationList = (districtAlias) => {
    apiCaller(`/api_organization/get_represent_code?district_alias=${districtAlias}&represent_code=${props?.registerInfo?.represent_code}`).then(
      (res) => {
        if(res?.status) {
          setOrganizationList(res.data)
        }
      }
    ).catch (err => {
      setOrganizationList([])
    })
  }

  const changeProvice = (newProvince) => {
    if (newProvince.value !== province.value) {
      setDistrict({
        title: "",
        value: "",
      });
      setSchool({
        title: "",
        value: "",
      });
      setProvince(newProvince);
      getDistrictList(newProvince.value);
    }
  };

  const changeDistrict = (newDistrict) => {
    if (newDistrict.value !== district.value) {
      setSchool({
        title: "",
        value: "",
      });
      setOrganization({
        title: "",
        value: "",
      });
      setDistrict(newDistrict);
      // getSchoolList(newDistrict.value);
      getOrganizationList(newDistrict.value);
    }
  };

  const changeSchool = (school) => {
    setSchool(school);
  };

  const changeOrganization = (org) => {
    setOrganization(org);
  };

  const validateParam = () => {
    if(!!district.value && !!organization.value)
      return true
    return false
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    props.setStep(stepAuthConstants.STEP_CREATE_ACCOUNT)
    props.setData({
      province: province.value,
      district: district.value,
      school: organization.title,
      school_id: organization.id,
      organization: organization.value
    })
  }
  
  useEffect(() => {
    if(!props?.registerInfo) {
      history.push('/')
      return;
    }
    if(province.value) {
      getDistrictList(province.value);
    }
    if (district.value) {
      getOrganizationList(district.value);
    }
  }, []);

  useEffect(() => {
    if(!!registerValue?.[orgLevel]?.represent_code) {
      if(!!registerValue[orgLevel]?.province_alias) {
        setProvince({
          title: '',
          value: registerValue[orgLevel].province_alias
        })
        getDistrictList(registerValue[orgLevel]?.province_alias);
      }
      if (!!registerValue[orgLevel]?.district_alias) {
        setDistrict({
          title: '',
          value: registerValue[orgLevel].district_alias
        })
        getOrganizationList(registerValue[orgLevel]?.district_alias);
      }
      props.setRegisterInfo(registerValue[orgLevel])
    }
  }, [orgLevel])

  return (
    <div className="sunE-form-container login-form-container register_create_account register_step">
      <div className="sunE-custom-form login-form-container_content responsive_small_screen_margin_login">
        <h2 className="title_register title text-center">ĐĂNG KÝ TÀI KHOẢN</h2>
        <h3 className="step_register_name text-center">1. Nơi công tác</h3>
        <form
          className="sunE-login-app"
          onSubmit={handleSubmit}
          noValidate={true}
          autoComplete="off"
        >
          <>
            {/* <InputSelectSearch
              className="field_item field_province"
              value={province}
              setValue={changeProvice}
              options={provinceList.map((item) => {
                return {
                  value: item.province_alias,
                  title: item.province,
                };
              })}
              placeholder="Tỉnh/Thành phố"
              titleHeader="Chọn Tỉnh/Thành phố"
              renderLabelIcon={renderLocationIcon}
              errorText={errProvince}
              setErrorText={setErrProvince}
              errorAbsolute={true}
              bottomErr={true}
            /> */}
            {!!props?.registerInfo?.province_alias && !props?.registerInfo?.district_alias && <InputSelectSearch
              className="field_item field_district"
              value={district}
              setValue={changeDistrict}
              options={districtList.map((item) => {
                return {
                  value: item.district_alias,
                  title: item.district,
                };
              })}
              placeholder="Quận/Huyện"
              titleHeader="Chọn Quận/Huyện"
              renderLabelIcon={renderLocationIcon}
              disabledClick={!province.value}
              onClickDisable={() => {
                setErrProvince("Vui lòng chọn tỉnh / thành phố");
              }}
              errorText={errDistrict}
              setErrorText={setErrDistrict}
              errorAbsolute={true}
              bottomErr={true}
            />}
            <InputSelectSearch
              className="field_item field_school"
              value={organization}
              setValue={changeOrganization}
              options={organizationList.map((item) => {
                return {
                  value: item.token,
                  title: item.name,
                  id: item.id
                };
              })}
              placeholder="Trường"
              titleHeader="Chọn Trường"
              renderLabelIcon={renderSchoolIcon}
              disabledClick={!district.value}
              onClickDisable={() => {
                setErrDistrict("Vui lòng chọn quận / huyện");
                if (!province.value) {
                  setErrProvince("Vui lòng chọn tỉnh / thành phố");
                }
              }}
              errorAbsolute={true}
              bottomErr={true}
            />
          </>
          <div className="button_submit">
            <button
              className={
                "btn-line-blue" + (!validateParam() ? " btn-disable" : "")
              }
            >
              Tiếp theo
            </button>
          </div>
          {/* <div className="login_text text-center">
            <p>
              <Link to="/login">Quay lại đăng nhập</Link>
            </p>
          </div> */}
        </form>
      </div>
    </div>
  )
}