import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function HeaderCloud() {
  const authentication = useSelector((state) => state.authentication);
  return (
    <div className="logo-nam-dinh">
      <Link to={`/namdinh`}>
        <img src={"/assets/images/Logo_ND.png"} alt={"Logo"} />
      </Link>
    </div>
  );
}

export { HeaderCloud };
