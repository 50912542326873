import "./ModalSearch.style.scss";
import InputText from "../../Auth/InputText";
import classnames from "classnames";
import { modalSearchLogic } from "./ModalSearch.logic";

const ModalSearch = (props) => {
  let {
    titleHeader,
    placeholderSearch,
    listDataOptions,
    handleCloseModal,
    handleSelectOption,
  } = props;

  let {
    listDataOptionsSide,
    valueSearch,
    setValueSearch,
    handleResetValueSearch,
    changeValueSearch,
  } = modalSearchLogic(props);

  // Render Auth Icon Search
  const renderAuthIcon = (type) => {
    return (
      <div
        className="flex-center pointer_cursor"
        // onClick={() => handleSearchMockTest()}
      >
        <img
          src={`/assets/images/authNews/ico_${type}.png`}
          alt="Icon Input"
          className="contain_image img_label_input"
        />
      </div>
    );
  };

  // Render Option
  const renderOptionSearch = (option, index) => {
    return (
      <div
        key={"option_" + index?.toString()}
        className="search_option_item pointer_cursor"
        onClick={() => handleSelectOption(option)}
      >
        <span className={classnames("title color-text")}>{option?.title}</span>
      </div>
    );
  };
  return (
    <div className="modal_search modal_not_scroll display_flex uk-flex-top uk-modal uk-flex uk-open commondPages col-100 flex-align flex-column">
      <div className="modal_search_container box-shadow">
        <div className="modal_search_header flex-jus-between">
          <div className="modal_search_title color-text font_myriad_bold fz-12-5">
            {titleHeader ?? ""}
          </div>
          <div
            onClick={handleCloseModal}
            className="modal_search_close flex-cente pointer_cursor"
          >
            <img
              src="/assets/images/icon/ico_close_menu.png"
              alt="Icon Close"
              className="contain_image"
            />
          </div>
        </div>

        <div className="search_input">
          <InputText
            typeSearch="search"
            handleResetValueSearch={handleResetValueSearch}
            value={valueSearch}
            setValue={changeValueSearch}
            type="text"
            placeholder={placeholderSearch ?? ""}
            required={true}
            renderLabelIcon={() => renderAuthIcon("search")}
            errorAbsolute={false}
            onFocus={() => setValueSearch(valueSearch?.trim())}
            onKeyDown={(e) => {
              if(e.key === "Enter") {
                e.preventDefault();
                if(!!listDataOptionsSide?.length) {
                  handleSelectOption(listDataOptionsSide[0])
                }
              }
            }}
          ></InputText>
        </div>

        <div className="list_option scrollbar-custom-3">
          {listDataOptionsSide && listDataOptionsSide?.length > 0 ? (
            listDataOptionsSide?.map((option, index) =>
              renderOptionSearch(option, index)
            )
          ) : (
            <div className="fz-11 color-tex text-align-center pd-top-1-pc pd-bottom-1">
              Không có kết quả tìm kiếm phù hợp.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalSearch;
