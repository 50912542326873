import "../ModalSuccessRegister/ModalSuccessRegister.style.scss";
import "../ModalSuccessMsg/ModalSuccessMsg.style.scss";
import "./ModalConfirmOTP.style.scss";
import ButtonNews from "../../Button/ButtonNews";
import { history } from "../../../_helpers";
import { TypeHeaderNewsItem } from "../../../_constants/headerNews";
import InputText from "../../Auth/InputText";
import { isEmpty } from "lodash";

const ModalConfirmOTP = (props) => {
  let {
    verificationCode,
    changeVerificationCode,
    handleConfirmOTP,
    handleResentVertifyCode,
    disabledBtnRegist,
    timeRemaining,
    codeSent,
    codeFailMessage,
    confirmTeacher,
    handleGoBack,
    messageReSendCode
  } = props;

  return (
    <div
      className={`flex-column modal_container hide modal_confirm_otp commondPage ${
        confirmTeacher ? "responsive_modal_authentication_teacher" : ""
      }`}
    >
      {/* <div className="flex-end">
        <div onClick={onClickClose} className="pointer_cursor">
          <img
            // loading="lazy"
            src="/assets/images/icon/ico_delete_full.png"
            alt="Icon Delete"
            className="contain_image img_delete_modal"
          />
        </div>
      </div> */}

      <div className="flex-center-column col-100-mobile">
        <div
          className={`col-100-mobile font_news_bold text-align-center fz-16-mobile fz-18 pd-bottom-1 mg-top-1 ${
            confirmTeacher ? "style_heading_authentication_teacher" : ""
          }`}
        >
          Xác thực tài khoản
        </div>
        {confirmTeacher && <div className="col-100-mobile font_news text-align-center pd-bottom-1">
          <span>Hệ thống đã gửi mã xác thực đến zalo và link xác thực đến email của bạn.</span>
          <br />
          <span>Vui lòng nhập mã để xác thực hoặc xác thực bằng link nhận được.</span>
          <br />
          <span>(Lưu ý: mã xác thực chỉ có giá trị trong 24h, link xác thực có giá trị trong 72h)</span>
        </div>}
        <div>
          <InputText
            value={verificationCode}
            setValue={changeVerificationCode}
            type="text"
            name="verification_code"
            placeholder="Nhập mã xác thực"
            required={true}
            // renderLabelIcon={() => renderAuthIcon("name")}
            // onBlur={() => {
            //   onBlurField("name");
            // }}
            autoFocus = {true}
          />
        </div>
        <div
          onClick={handleResentVertifyCode}
          className={`pointer_cursor color-red fz-1 font_news_bold font_style_italic text_underline ${
            confirmTeacher ? "confirmTeacher" : ""
          } ${codeSent ? 'color-gray' : ''}`}
          style={codeSent ? { cursor: "not-allowed"} : {}}
        >
          Gửi lại mã{" "}
          {`${timeRemaining != 0 && codeSent ? `(${timeRemaining}s)` : ""}`}
        </div>
        <div className="flex-center mg-top-1 msg_resent_code hide">
          <img
            src={messageReSendCode && !isEmpty(messageReSendCode) ? "/assets/images/icon/ico_delete_full.png" : `/assets/images/icon/ico_tick_success.png`}
            alt="Tick Success"
            className="contain_image img_tick_success"
          />
          <div className={`fz-09 mg-left-1 ${messageReSendCode && !isEmpty(messageReSendCode) ? ' color-red ' : ' color-primary-green'}`}>{messageReSendCode && !isEmpty(messageReSendCode) ? `${messageReSendCode}` : `Mã xác thực đã được gửi lại`}</div>
        </div>
        <div className="flex-center mg-top-1 msg_fail_code hide">
          <img
            src="/assets/images/icon/ico_delete_full.png"
            alt="Tick fail"
            className="contain_image img_tick_success"
          />
          <div className="fz-09 mg-left-1 color-red">
            {codeFailMessage != ""
              ? `${codeFailMessage}`
              : "Mã xác thực đã hết hiệu lực"}
          </div>
        </div>
        <div
          className={`btn_auth_container flex-center col-100 ${
            confirmTeacher ? "style_btn_agree_authentication_teacher" : ""
          }`}
        >
          <ButtonNews
          className={'otp-btn-responsive'}
            width="15rem"
            height="3.5rem"
            onClick={handleConfirmOTP}
            border={"1px solid #00cc83"}
            backgroundColor={"#00cc83"}
            boxShadow={"2px 2px 5px white, 0 0 1px black"}
            maxWidth={"40%"}
            maxHeight={"8vw"}
            disabled={disabledBtnRegist}
          >
            <div className="fz-12 fz-1-mobile color-white font_news_bold">
              XÁC NHẬN
            </div>
          </ButtonNews>
          {/* <ButtonNews
          className={'otp-btn-responsive'}
          width="15rem"
          height="3.5rem"
          onClick={() => window.location.replace('https://sundayenglish.com/login')}
          border={"1px solid #00cc83"}
          backgroundColor={"#fff"}
          maxWidth={"45%"}
          maxHeight={"7vw"}
        >
          <div className="fz-12 fz-09-mobile  color-primary-green font_news_bold">
            ĐĂNG NHẬP
          </div>
        </ButtonNews> */}
        </div>
      </div>
    </div>
  );
};

export default ModalConfirmOTP;
